import type { Component } from 'vue';
import {
	ACHIEVEMENT_UNLOCKED_MODAL,
	ANNOUNCEMENT_MODAL_VERSION,
	ASK_TRACKING_PERMISSIONS_MODAL,
	BONUS_CODE_MODAL,
	BONUS_DAY_MODAL,
	CASHBACK_ANNOUNCEMENT_MODAL,
	CASHBACK_RECEIVED_NOTIFICATION_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_LANGUAGE_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_USERNAME_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	CONFIRM_CLAIM_MODAL,
	COPY_REFERRAL_LINK_MODAL,
	DELETE_ACCOUNT_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	GOOGLE_TRANSLATE_MODAL,
	LEADERBOARD_MODAL,
	NEW_LEVELS_MODAL,
	NEW_PASSWORD_SENT,
	OFFERWALLS_PENDING_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
	ONE_CLICK_SURVEY_MODAL,
	PRESET_WELCOME_BONUS_MODAL,
	PRIZE_BONUS_MODAL,
	PRIZE_DRAW_MODAL,
	REWARD_SENT_MODAL,
	REWARDS_MODAL,
	SELECT_COUNTRY_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	UPDATE_AVAILABLE_MODAL,
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	ALLOW_LOCATION_MODAL,
} from '@/constants/modals';
import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import UserCongratsLevelModal from '@/components/modals/CongratsLevelModal.vue';
import UserCongratsStreakModal from '@/components/modals/CongratsStreakModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import ChangeUsernameModal from '@/components/modals/ChangeUsernameModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import PresetWelcomeBonusModal from '@/components/modals/PresetWelcomeBonusModal.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import LeaderboardModal from '@/components/modals/LeaderboardModal.vue';
import PrizeDrawModal from '@/components/modals/PrizeDrawModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import AchievementUnlockedModal from '@/components/modals/AchievementUnlockedModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import OneClickSurveyModal from '@/components/modals/OneClickSurveyModal.vue';
import SelectCountryLanguageModal from '@/components/modals/SelectCountryLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import NewLevelsModal from '@/components/modals/NewLevelsModal.vue';
import ConfirmPaypalEmailModal from '@/components/modals/ConfirmClaimDataModal.vue';
import BonusCodeModal from '@/components/modals/BonusCodeModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import GoogleTranslateModal from '@/components/modals/GoogleTranslateModal.vue';
import BonusDayModal from '@/components/modals/BonusDayModal.vue';
import PrizeBonusModal from '@/components/modals/PrizeBonusModal.vue';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import OfferwallRewardNotificationModal from '@/components/modals/notifications/OfferwallRewardNotificationModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import CopyReferralLinkModal from '@/components/modals/CopyReferralLinkModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import OfferwallsWellDoneModal from '@/components/modals/OfferwallsWellDoneModal.vue';
import OfferwallsPendingModal from '@/components/modals/OfferwallsPendingModal.vue';
import AnnouncementModalVersion from '@/components/modals/AnnouncementModalVersion.vue';
import CashbackAnnouncementModal from '@/components/modals/announcements/CashbackAnnouncementModal.vue';
import CashbackRecievedNotificationModal from '@/components/modals/notifications/CashbackRecievedNotificationModal.vue';
import AllowLocationModal from '@/components/modals/AllowLocationModal.vue';

export const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[USER_LEVEL_MODAL]: UserCongratsLevelModal,
	[USER_STREAK_MODAL]: UserCongratsStreakModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[CHANGE_USERNAME_MODAL]: ChangeUsernameModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[PRESET_WELCOME_BONUS_MODAL]: PresetWelcomeBonusModal,
	[REWARDS_MODAL]: RewardsModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[LEADERBOARD_MODAL]: LeaderboardModal,
	[PRIZE_DRAW_MODAL]: PrizeDrawModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[ACHIEVEMENT_UNLOCKED_MODAL]: AchievementUnlockedModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[ONE_CLICK_SURVEY_MODAL]: OneClickSurveyModal,
	[SELECT_COUNTRY_LANGUAGE_MODAL]: SelectCountryLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[NEW_LEVELS_MODAL]: NewLevelsModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmPaypalEmailModal,
	[BONUS_CODE_MODAL]: BonusCodeModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[GOOGLE_TRANSLATE_MODAL]: GoogleTranslateModal,
	[BONUS_DAY_MODAL]: BonusDayModal,
	[PRIZE_BONUS_MODAL]: PrizeBonusModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[OFFERWALLS_REWARD_NOTIFICATION_MODAL]: OfferwallRewardNotificationModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[COPY_REFERRAL_LINK_MODAL]: CopyReferralLinkModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[OFFERWALLS_WELL_DONE_MODAL]: OfferwallsWellDoneModal,
	[OFFERWALLS_PENDING_MODAL]: OfferwallsPendingModal,
	[ANNOUNCEMENT_MODAL_VERSION]: AnnouncementModalVersion,
	[CASHBACK_ANNOUNCEMENT_MODAL]: CashbackAnnouncementModal,
	[CASHBACK_RECEIVED_NOTIFICATION_MODAL]: CashbackRecievedNotificationModal,
	[ALLOW_LOCATION_MODAL]: AllowLocationModal,
};
