import { defineStore, storeToRefs } from 'pinia';
import type { ErrorResponse, AppMeta } from '@/types';
import { getErrorResponse } from '@/utils/helpers';
import { getAppMeta, sendDeviceMaid } from '@/api';
import {
	appLogStorage,
	isInAppBrowserStorage,
	maidStorage,
	showAlerts,
} from '@/utils/local-storage';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import {
	Adjust,
	AdjustConfig,
	AdjustEvent,
} from '@awesome-cordova-plugins/adjust';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { AdvertisingStatuses, TrackingEvents } from '@/enums';
import { useGtm } from '@gtm-support/vue-gtm';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';
// @ts-ignore
import PlaytimePlugin from 'cordova-plugin-adjoe/www/playtime';

interface AppState {
	meta: AppMeta | null;
	appMetaLoading: boolean;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	qualificationInProgress: boolean;
	tracking: boolean;
	trackingMaid: string;
	adjustConfig: AdjustConfig | null;
	appLog: string[];
	advertisingStatus: AdvertisingStatuses
	nativeUpdate: boolean;
	isAdjustEnabled: boolean;
}

export const useAppStore = defineStore('app', {
	state: (): AppState => ({
		meta: null,
		loading: false,
		initLoading: false,
		error: null,
		qualificationInProgress: false,
		tracking: false,
		trackingMaid: '',
		adjustConfig: null,
		appLog: [],
		advertisingStatus: AdvertisingStatuses.NOT_DETERMINED,
		nativeUpdate: false,
		appMetaLoading: false,
		isAdjustEnabled: false,
	}),

	getters: {
		appMeta: (state) => state.meta,
		adjustEvents: (state) => state.meta?.adjust_events || null,
		isQualificationInProgress: (state) => state.qualificationInProgress,
		getAdjustConfig: (state) => state.adjustConfig,
		appLogMessages: (state) => state.appLog,
		isNativeAppTrackingEnabled: (state) => state.tracking,
		getTrackingMaid: (state) => state.trackingMaid,
		getAdvertisingStatus: (state) => state.advertisingStatus,
		isNativeUpdateAvailable: (state) => state.nativeUpdate,
		isAdjoeEnabled: (state) => state.meta?.adjoe_enabled || false,
		isAppMetaLoading: (state) => state.appMetaLoading,
	},

	actions: {
		async fetchAppMeta() {
			try {
				this.appMetaLoading = true;
				this.meta = await getAppMeta();
			} catch (e) {
				this.error = getErrorResponse(e);
			} finally {
				this.appMetaLoading = false;
			}
		},

		setQualificationInProgress(value: boolean) {
			this.qualificationInProgress = value;
		},

		async initAdjust() {
			const { isIos } = useMobileApp();

			if (!this.isAdjustEnabled || isIos.value) {
				const config = new AdjustConfig(
					import.meta.env.ADJUST_TOKEN,
					import.meta.env.ADJUST_TYPE
				);
				config.setAttConsentWaitingInterval(60);
				Adjust.initSdk(config);

				this.isAdjustEnabled = await Adjust.isEnabled();
			}
		},

		trackAdjustEvent(eventName: string) {
			const event = new AdjustEvent(eventName);
			const { userId } = storeToRefs(useUserStore());

			if (userId.value) {
				event.addCallbackParameter('user_id', userId.value);
			}

			Adjust.trackEvent(event);
		},

		async setUser(userData: { [key: string]: string }): Promise<void> {
			const { marketingId } = useAppCookies();
			const { isMobileApp } = useMobileApp();

			if (!this.tracking || !marketingId || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.setUserId({
				userId: marketingId,
			});
			for (const key in userData) {
				await FirebaseAnalytics.setUserProperty({
					key: key,
					value: userData[key],
				});
			}
		},

		async setTracking(maid: string): Promise<void> {
			const { isMobileApp, isInAppBrowser } = useMobileApp();
			this.trackingMaid = maid;
			this.tracking = !!maid;

			this.addLog(`SET TRACKING:::enabled: ${this.tracking}`);
			if (!isMobileApp || isInAppBrowser.value) {
				return;
			}
			await FirebaseAnalytics.setEnabled({
				enabled: this.tracking,
			});
		},

		async resetTracking(): Promise<void> {
			const { isMobileApp } = useMobileApp();
			if (!this.tracking || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.resetAnalyticsData();
		},

		async setCurrentScreen(screenName: string): Promise<void> {
			const { isMobileApp } = useMobileApp();
			if (!this.tracking || !isMobileApp) {
				return;
			}
			await FirebaseAnalytics.setCurrentScreen({
				screenName,
			});
		},

		async trackEvent(
			event: TrackingEvents,
			params: Record<string, string> = {},
			adjustEventName: string | undefined = undefined
		): Promise<void> {
			const { marketingId } = useAppCookies();
			const { isMobileApp, platform, isInAppBrowser } = useMobileApp();

			const trackedWith = ref<string[]>([]);

			this.addLog(
				`TRACK EVENT STARTING:::ENABLED:::${this.tracking}:::EVENT:::${event}:::PARAMS:::${JSON.stringify(params)}:::IN APP BROWSER:::${isInAppBrowserStorage.value}:::IS MOBILE:::${isMobileApp}`
			);

			if (isMobileApp && !this.tracking) {
				this.addLog(`TRACK EVENT ENABLED:::RETURNED:::NOT ENABLED?`);
				this.addLog(`TRACK EVENT ENDING====================`);
				return;
			}
			if (isMobileApp) {
				this.addLog(
					`TRACK EVENT NATIVE BEFORE:::${event}:::PARAMS:::${JSON.stringify(params)}:::PLATFORM:::${platform.value}`
				);

				await FirebaseAnalytics.logEvent({
					name: event,
					params,
				});

				this.addLog(
					`TRACK EVENT:::DONE FB:::${event}:::${JSON.stringify(params)}`
				);

				trackedWith.value.push('FB');

				this.addLog(
					`TRACK EVENT:::BEFORE ADJUST:::${adjustEventName}:::${this.adjustEvents ? this.adjustEvents[event] : undefined}`
				);
				const adjustEvent = adjustEventName
					? adjustEventName
					: this.adjustEvents
						? this.adjustEvents[event]
						: undefined;

				if (adjustEvent) {
					this.trackAdjustEvent(adjustEvent);
					this.addLog(
						`TRACK EVENT:::DONE ADJUST:::${adjustEvent}:::${JSON.stringify(params)}`
					);

					trackedWith.value.push('ADJUST');
				}
			} else {
				this.addLog(
					`TRACK EVENT:::GTM:::${event}:::PLATFORM:::${platform.value}`
				);

				if (isInAppBrowser.value) {
					return;
				}

				const gtm = useGtm();
				gtm?.trackEvent({
					event: event,
					user_id: marketingId || '',
					customer: {
						id: marketingId || '',
					},
					...params,
				});

				trackedWith.value.push('GTM');
			}

			if (showAlerts.value) {
				alert(
					`TRACKED EVENT ::: ${event} WITH:::${trackedWith.value.join(', ')}`
				);
			}

			this.addLog(`TRACK EVENT ENDING====================`);
		},
		async handleTrackingPermissions() {
			const status = await Adjust.requestAppTrackingAuthorization();
			this.addLog(`TRACKING MODAL RESULT::: status - ${status}`);
			this.setAdvertisingStatus(status);

			if (status === AdvertisingStatuses.AUTHORIZED) {
				maidStorage.value = await Adjust.getIdfa();
				this.addLog(`IDFA VALUE::: idfa - ${maidStorage.value}`);
				await this.setTracking(maidStorage.value);
				await this.trackEvent(TrackingEvents.APP_INIT);
			}
		},
		addLog(message: string) {
			const { authToken } = useAppCookies();
			if (appLogStorage.value || !authToken) {
				this.appLog.push(message);
			}
		},
		clearLog() {
			this.appLog = [];
		},
		async storeMaid() {
			try {
				const status = await sendDeviceMaid(this.trackingMaid);
				this.addLog(
					`SET TRACKING:::MAID:::${this.trackingMaid}:::SENT status: ${status}`
				);
			} catch (e) {
				this.addLog(
					`SEND MAID ERROR:::MAID:::${this.trackingMaid}:::ERROR:::${e}`
				);
			}
		},
		setAdvertisingStatus(status: AdvertisingStatuses) {
			this.advertisingStatus = status;
		},
		setNativeUpdate(value: boolean) {
			this.nativeUpdate = value;
		},
		checkIsAdjoeInitialized() {
			PlaytimePlugin.isInitialized(
				() => {
					this.addLog('ADJOE INITIALIZED:::SUCCESS');
				},
				() => {
					this.addLog('ADJOE INITIALIZED:::FAILED');
				}
			);
		},
		async initAdjoe() {
			await this.waitForAppMeta();

			const { isUserAdjoeEnabled } = storeToRefs(useUserStore());
			if (!this.isAdjoeEnabled || !isUserAdjoeEnabled.value) {
				this.addLog(
					`ADJOE NOT ENABLED ::: GLOBAL :::${this.isAdjoeEnabled} ::: USER:::${isUserAdjoeEnabled.value}`
				);
				return;
			}
			try {
				const { isAndroid, isTablet } = useMobileApp();
				const { userId } = storeToRefs(useUserStore());
				const options = {
					...(userId.value && { user_id: userId.value }),
				};
				if (isAndroid.value && !isTablet()) {
					PlaytimePlugin.initialize(
						import.meta.env.VITE_ADJOE_HASH,
						options,
						() => {
							this.addLog(
								`ADJOE INITIALIZED:::options:::${JSON.stringify(options)}`
							);
						},
						() => {
							this.addLog('ADJOE INITIALIZED:::FAILED');
						}
					);
				}
			} catch (e) {
				this.addLog(`ADJOE INIT ERROR:::${e}`);
			}
		},
		showAdjoeOfferwall() {
			const { isUserAdjoeEnabled } = storeToRefs(useUserStore());
			if (!isUserAdjoeEnabled.value || !this.isAdjoeEnabled) return;
			try {
				PlaytimePlugin.showCatalog(
					() => {
						this.addLog('ADJOE SHOW OFFERWALL:::SUCCESS');
					},
					() => {
						this.addLog('ADJOE SHOW OFFERWALL:::FAILED');
					}
				);
			} catch (e) {
				this.addLog(`ADJOE SHOW OFFERWALL ERROR:::${e}`);
			}
		},
		showAdjoeTeaser() {
			const { isUserAdjoeEnabled } = storeToRefs(useUserStore());
			if (!isUserAdjoeEnabled.value || !this.isAdjoeEnabled) return;
			try {
				PlaytimePlugin.sendUserEvent(
					PlaytimePlugin.EVENT_TEASER_SHOWN,
					() => {
						this.addLog(
							`ADJOE TEASER SHOWN:::${PlaytimePlugin.EVENT_TEASER_SHOWN}`
						);
					},
					() => {
						this.addLog(
							`ADJOE TEASER SHOWN FAILED:::${PlaytimePlugin.EVENT_TEASER_SHOWN}`
						);
					}
				);
			} catch (e) {
				this.addLog(`ADJOE TEASER ERROR:::${e}`);
			}
		},
		async waitForAppMeta() {
			if (!this.meta) {
				// Wait for initial app meta loading
				await new Promise((resolve) => {
					const checkAppMetaLoading = () => {
						if (!this.isAppMetaLoading) {
							clearInterval(interval);
							resolve(null);
						}
					};
					const interval = setInterval(checkAppMetaLoading, 50);
					// Check immediately
					checkAppMetaLoading();
				});
			}
		},
	},
});
