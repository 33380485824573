import { fetchCountryCode } from '@/api';
import type { AxiosError } from 'axios';
import type {
	ErrorResponse,
	Rewards,
	RewardsItem,
	SocialCallbackResponse,
} from '@/types';
import { currencies } from '@/constants/currencies';
// @ts-ignore
import {
	countries,
	type ICountry,
	type ILanguage,
	languages as languagesAll,
} from 'countries-list';
import { localizations } from '@/constants/localizations';
import { defaultCountry, defaultLanguage, getCurrentLocale } from '@/i18n';
import MobileDetect from 'mobile-detect';
import { useAppCookies } from './composables/useAppCookies';
import { useMobileApp } from './composables/useMobileApp';
import {
	formatDuration,
	intervalToDuration,
	format,
	parseISO,
	type Locale,
} from 'date-fns';
import { RewardCategories } from '@/enums';

export const getIconPath = (icon: string): string =>
	`src/assets/icons/${icon}.svg`;

export const getErrorResponse = (error: unknown) =>
	(error as AxiosError).response?.data as ErrorResponse;

/**
 * Transforms date to 'Jan 6, 2021' format.
 * Optionally to 'Friday, Jan 6, 2021' format.
 */
export const toDate = (
	rawDate: string,
	localization: string = 'en-US',
	hasWeekday = false
) => {
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		...(hasWeekday && { weekday: 'long' }), // optionally object prop
	} as Intl.DateTimeFormatOptions;
	return new Date(rawDate).toLocaleDateString(localization, options);
};

export const getRootDomain = (withProtocol: boolean = false): string => {
	const pattern: RegExp = /app\.(\S+)/;
	const matches = window.location.hostname.match(pattern);

	const { isMobileApp } = useMobileApp();

	if (isMobileApp) {
		return import.meta.env.VITE_HOME_PAGE;
	}

	if (!Array.isArray(matches) || matches.length < 2) {
		return '';
	}

	if (withProtocol) {
		return import.meta.env.MODE === 'development'
			? `http://${matches[1]}:${import.meta.env.VITE_DEV_SERVER_PORT}`
			: `https://${matches[1]}`;
	}

	return matches[1];
};

export const getLocaleValue = (): string => {
	const { localizationCookie } = useAppCookies();
	const supportedLocales = Intl.NumberFormat.supportedLocalesOf(
		localizationCookie || 'en-US'
	);

	return supportedLocales.length !== 0 ? supportedLocales[0] : 'en-US';
};

export const transformCurrency = (
	money: number = 0,
	currency: string = '',
	maximumFractionDigits: number = 0
): string => {
	!money ? (money = 0) : '';
	const moneyValue = typeof money === 'string' ? parseInt(money) : money;

	let result = `${money} ${currency}`;

	try {
		const localedCurrency = new Intl.NumberFormat(getLocaleValue(), {
			currency: currency,
			style: 'currency',
			currencyDisplay: 'symbol',
			currencySign: 'standard',
			maximumFractionDigits:
				maximumFractionDigits > 2 ? 2 : maximumFractionDigits,
		}).format(moneyValue);

		if (
			localedCurrency.includes(currency) ||
			currencies[currency] === '$' ||
			money.toString() === localedCurrency
		) {
			result = `${currencies[currency]} ${money} ${currency}`;
		} else {
			result = localedCurrency;
		}
	} catch (error) {
		if (error instanceof RangeError) {
			const localedCurrency = money.toFixed(maximumFractionDigits);

			result = currencies[currency]
				? `${currencies[currency]} ${localedCurrency} ${currency}`
				: `${localedCurrency} ${currency}`;
		}
	}

	return result;
};

export const localizePoints = (points: number | string): string => {
	const pointsValue = typeof points === 'string' ? parseInt(points) : points;
	return pointsValue.toLocaleString(getLocaleValue());
};

export const getLanguage = (localization: string | null) =>
	localization?.split('-')[0] || defaultLanguage;
export const getCountryCode = (localization: string | null) => {
	return localization?.split('-')[1] || defaultCountry;
};

export const getCountryName = (countryCode: string) => {
	const _countries = { ...countries } as Record<string, ICountry>;
	return _countries[countryCode.toUpperCase()].native;
};

export const getCountryLanguages = (countryCode: string): string[] =>
	localizations
		.filter((locale) => locale.country === countryCode)
		.map((locale) => locale.code);

export const getLanguageNative = (localization: string) => {
	const _languagesAll = { ...languagesAll } as Record<string, ILanguage>;
	const languageInfo = _languagesAll[getLanguage(localization)];
	if (languageInfo) {
		return languageInfo.native;
	} else if (localization === 'fil-ph') {
		return 'Filipino';
	}
};

export const getInitialUserLocalization = async (): Promise<string> => {
	let localization = '';

	const { code } = await fetchCountryCode();

	const locales = localizations.filter(
		(locale) => locale.country === code.toLowerCase()
	);

	if (locales.length === 0) {
		localization = 'en-us';
	}

	if (locales.length === 1) {
		localization = locales[0].code;
	} else if (locales.length > 1) {
		const filteredLanguages = navigator.languages.filter((language) =>
			locales.some((locale) => locale.language === language.toLowerCase())
		);
		const defaultLocale = locales.find((locale) => locale.default)!;

		if (filteredLanguages.length > 0) {
			localization = `${filteredLanguages[0]}-${code.toLowerCase()}`;
		} else {
			localization = defaultLocale.code;
		}
	}

	return localization;
};

export const getSocialAuthErrorResponse = (
	error: unknown
): { status: number; data: SocialCallbackResponse } => {
	const { response } = error as AxiosError<SocialCallbackResponse>;
	const {
		status,
		data,
	}: {
		status: number;
		data: SocialCallbackResponse;
	} = response!;

	return {
		status,
		data,
	};
};

export const isMobileBrowser = () => {
	const userAgent = window.navigator.userAgent;

	const mobileDetect = new MobileDetect(userAgent);

	return Boolean(mobileDetect.mobile());
};

export const createObjectFromString = (str: string) => {
	const keyValuePairs = str.split('&');
	const obj: Record<string, string> = {};

	for (const pair of keyValuePairs) {
		const [key, value] = pair.split('=');
		obj[key] = decodeURIComponent(value);
	}
	return obj;
};

export const getRedirectRoute = (url: string): string => {
	const urlObject = new URL(url);
	const path = urlObject.pathname;
	const segments = path.split('/').filter((segment) => segment !== ''); // Remove empty segments
	return segments[segments.length - 1];
};

export const hexToRgba = (hex: string, alpha: number = 1) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	let rgb = '';
	if (result) {
		rgb =
			parseInt(result[1], 16) +
			', ' +
			parseInt(result[2], 16) +
			', ' +
			parseInt(result[3], 16);
	} else {
		rgb = '22, 78, 208';
	}

	return alpha ? rgb + ', ' + alpha : rgb;
};

export const isUserDev = (email: string | undefined): boolean => {
	const regex = /@primeinsights\.com$/i;
	return email ? regex.test(email) : false;
};

export const sortRewards = (rewards: Rewards): Rewards => {
	const sortFunction = (a: RewardsItem, b: RewardsItem) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
		return 0;
	};

	return {
		...(rewards[RewardCategories.PROMOTED] && {
			[RewardCategories.PROMOTED]:
				rewards[RewardCategories.PROMOTED].sort(sortFunction),
		}),
		...(rewards[RewardCategories.TRANSFERS] && {
			[RewardCategories.TRANSFERS]:
				rewards[RewardCategories.TRANSFERS].sort(sortFunction),
		}),
		...(rewards[RewardCategories.GIFT_CARDS] && {
			[RewardCategories.GIFT_CARDS]:
				rewards[RewardCategories.GIFT_CARDS].sort(sortFunction),
		}),
		...(rewards[RewardCategories.PREPAID_CARDS] && {
			[RewardCategories.PREPAID_CARDS]:
				rewards[RewardCategories.PREPAID_CARDS].sort(sortFunction),
		}),
		...(rewards[RewardCategories.CHARITY] && {
			[RewardCategories.CHARITY]:
				rewards[RewardCategories.CHARITY].sort(sortFunction),
		}),
	};
};

export const getCountdown = (date: string, dateLocale: any) => {
	const duration = intervalToDuration({
		start: Date.now(),
		end: new Date(date),
	});

	return formatDuration(duration, {
		locale: dateLocale,
		format: ['days', 'hours', 'minutes'],
		delimiter: ', ',
	});
};

export const getHiddenEmail = (email: string): string => {
	const [userName, domain] = email.split('@');

	if (userName.length > 4) {
		const formattedUsername =
			userName.substring(0, 2) +
			'***' +
			userName.substring(userName.length - 2);
		return `${formattedUsername}@${domain}`;
	} else {
		const formattedUsername =
			userName.substring(0, 1) +
			'***' +
			userName.substring(userName.length - 1);
		return `${formattedUsername}@${domain}`;
	}
};

export const randomizeChance = (probability: number): boolean => {
	return Math.random() < probability;
};

export const formatExpirationDate = (date: string): Promise<string> => {
	return import('date-fns/locale').then((module) => {
		const locale = (module as Record<string, Locale>)[getCurrentLocale()];

		return format(parseISO(date), 'MMMM do', { locale });
	});
};
